/* eslint-disable no-unused-vars */
import * as THREE from "three";
import { CubeTexture, CubeTextureLoader, LoadingManager, Mesh } from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { setupGlftModelSingleChild } from "../helper/TalkToUser";

export class UniqueLoaders {
  clock: THREE.Clock;
  elapsedTime: number;

  textureLoaderTextTitle: THREE.TextureLoader;
  fontLoader: FontLoader;
  glftLoader: GLTFLoader;
  CubeTextureLoader: CubeTextureLoader;

  nbModelOfBottle: number;
  bottleModelArrray: THREE.Mesh[];
  bottleModelMateriel: THREE.MeshPhysicalMaterial;
  colorModelTexture: THREE.Texture;

  nbModelOfCubeMap: number;
  cubeMapArray: CubeTexture[];
  skyBox: Mesh[];

  constructor() {
    this.clock = new THREE.Clock();
    this.elapsedTime = this.clock.getElapsedTime();
    // this.loadingManager = new THREE.LoadingManager();
    // this.loading();
    this.textureLoaderTextTitle = new THREE.TextureLoader();
    this.fontLoader = new FontLoader();
    this.glftLoader = new GLTFLoader();
    this.CubeTextureLoader = new CubeTextureLoader();

    this.bottleModelArrray = [];
    this.nbModelOfBottle = 3;
    this.bottleModelMateriel = new THREE.MeshPhysicalMaterial();
    this.colorModelTexture = new THREE.Texture();

    this.cubeMapArray = [];
    this.nbModelOfCubeMap = 8;

    this.skyBox = [];

    this.loadBottleArray();
    this.loadCubeMapArray();
    this.loadCubeMapArray();
  }

  loadBottleArray = async () => {
    for (let i = 0; i < this.nbModelOfBottle; i++) {
      this.bottleModelArrray.push(
        setupGlftModelSingleChild(await this.glftLoader.loadAsync(`/model/bottle/bottle${i}.glb`))
      );
      this.bottleModelArrray[i].material = this.bottleModelMateriel;
    }
  };

  loadCubeMapArray = async () => {
    for (let i = 0; i < this.nbModelOfCubeMap; i++) {
      this.cubeMapArray.push(
        this.CubeTextureLoader.load([
          `/textures/environmentMaps/${i}/px.jpg`,
          `/textures/environmentMaps/${i}/nx.jpg`,
          `/textures/environmentMaps/${i}/py.jpg`,
          `/textures/environmentMaps/${i}/ny.jpg`,
          `/textures/environmentMaps/${i}/pz.jpg`,
          `/textures/environmentMaps/${i}/nz.jpg`
        ])
      );
      await this.loadSkyBox(i);
    }
  };

  loadSkyBox = async (i: number) => {
    const cubeShader = THREE.ShaderLib.cube;
    cubeShader.uniforms.tCube.value = this.cubeMapArray[i];
    cubeShader.uniforms.opacity.value = 0.8; // semi-transparent skybox

    const skyBoxMaterial = new THREE.ShaderMaterial({
      fragmentShader: cubeShader.fragmentShader,
      vertexShader: cubeShader.vertexShader,
      uniforms: cubeShader.uniforms,
      side: THREE.BackSide,
      transparent: true
    });

    this.skyBox.push(new THREE.Mesh(new THREE.BoxGeometry(15000, 15000, 15000), skyBoxMaterial));
    console.log(this.skyBox[i]);
  };
}

// loading = () => {
//   // alert("test");
//   this.loadingManager.onStart = () => {
//     console.log("Onstart");
//   };
//   this.loadingManager.onLoad = () => {
//     console.log("onLoad");
//   };
//   this.loadingManager.onProgress = (item, loaded, total) => {
//     console.log("onProgress");
//     // pourcent_loaded.innerText = Math.round(loaded / total * 100, 2) + '%'
//   };
//   this.loadingManager.onError = () => {
//     console.log("onError");
//   };
// };
