import React, { useState, useEffect } from "react";
import { getCookie } from "../components/helper/TalkToUser";
import isLogged, { hardLogout } from "../services/authService";
import { getUserByUsername } from "../services/userService";
import { user, UserContextType } from "../types/userType";

const AuthContext = React.createContext<UserContextType | null>(null);

const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setUser] = useState<user>({
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    createdAt: 0
  });
  const [isLoggedContext, setIsLogged] = useState(false);

  const getCurrentUser = async () => {
    try {
      const logged = await isLogged();
      if (logged) {
        const authUser: user = await getUserByUsername(
          localStorage.getItem("aimUsername") as string,
          localStorage.getItem("aimUserid") as string
        );
        setUser(authUser);
      } else {
        setIsLogged(false);
      }
    } catch (err) {
      hardLogout();
      window.open("/", "_self");
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    updateIsLogged();
  }, [currentUser]);

  // useEffect(() => {}, [isLogged]);

  const updateUser = (updatedUser: user) => {
    setUser({
      id: updatedUser.id,
      username: updatedUser.username,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      email: updatedUser.email,
      telephone: updatedUser.telephone,
      createdAt: updatedUser.createdAt
    });
  };

  const updateIsLogged = () => {
    if (getCookie("XSRF-TOKEN")) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  };

  const removeUser = () => {
    setUser({
      id: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      createdAt: 0
    });
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLoggedContext,
        updateUser,
        updateIsLogged,
        removeUser,
        getCurrentUser
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const useCurrentUser = () => React.useContext(AuthContext);

export { AuthProvider, AuthContext, useCurrentUser };
