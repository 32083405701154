import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faAward, faHome, faSearch, faSign, faUser } from "@fortawesome/free-solid-svg-icons";
import TopMenuItem from "./TopMenuItem";
import menuxpand from "../images/menuxpand.png";
import Signup from "./Signup";
import Login from "./Login";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import Logout from "./Logout";
import { AuthContext } from "../contexts/AuthProvider";
import { UserContextType } from "../types/userType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IProps = {
  menuState: boolean;
};

const TopMenu: React.FC<IProps> = ({ menuState }) => {
  const { isLoggedContext, currentUser } = useContext(AuthContext) as UserContextType;
  const [menuShow, setmenuShow] = useState(menuState);
  const [modalSignupVisible, setModalSignupVisible] = useState(false);
  const [modalSigninVisible, setModalSigninVisible] = useState(false);

  const showSignupModal = () => {
    setModalSignupVisible(true);
    const divModalSignup = document.getElementById("modalSignup");
    if (divModalSignup) {
      divModalSignup!.className = "fixed z-30 border-8 border-robins-egg-blue-50 bg-opacity-50";
    }
  };

  const hideSignupModal = () => {
    setModalSignupVisible(false);
  };
  const showSigninModal = () => {
    setModalSigninVisible(true);
    const divModalSignin = document.getElementById("modalSignin");
    if (divModalSignin) {
      divModalSignin!.className = "fixed z-30 border-8 border-robins-egg-blue-50 bg-opacity-50";
    }
  };

  const hideSigninModal = () => {
    setModalSigninVisible(false);
  };

  useEffect(() => {
    const divMenu = document.getElementById("menu") as HTMLInputElement;
    if (menuShow === false) {
      divMenu.className = " invisible w-0 h-0 z-0";
    } else {
      divMenu.className = " visible z-30";
    }
  }, []);

  const showMenu = (): void => {
    const divMenu = document.getElementById("menu") as HTMLInputElement;
    if (menuShow) {
      divMenu.className = " invisible w-0 h-0 z-0";
      setmenuShow(false);
      localStorage.setItem("showMenuAimbottled", "false");
    } else {
      divMenu.className = " visible z-30";
      setmenuShow(true);
      localStorage.setItem("showMenuAimbottled", "true");
    }
  };
  return (
    <div className="flex flex-col w-full">
      <div className="absolute m-2 -top-0.5 rounded-xl h-14 w-14 z-40">
        <img id="menuimg" onClick={showMenu} className=" rounded-lg" src={menuxpand} alt="menu" />
      </div>

      {modalSignupVisible ? (
        <div id="modalSignup" className="flex justify-center z-30 border-robins-egg-blue-50">
          <Signup showModal={modalSignupVisible} handleClose={hideSignupModal} />
        </div>
      ) : (
        <div></div>
      )}

      {modalSigninVisible ? (
        <div id="modalSignin " className="flex justify-center z-30 border-robins-egg-blue-50">
          <Login showModal={modalSigninVisible} handleClose={hideSigninModal} />
        </div>
      ) : (
        <div></div>
      )}

      <div id="menu" className="hidden z-40">
        <div className="flex justify-between w-auto h-16">
          <div className="flex"></div>
          <div className="flex">
            <div>
              <Link to="/accueil">
                <TopMenuItem icon={faHome} name="home" />
              </Link>
            </div>
            <div>
              {isLoggedContext ? (
                <Link to="/bottleThrow">
                  <TopMenuItem icon={faDropbox} name="Jeter une bouteille" />
                </Link>
              ) : (
                <div></div>
              )}
            </div>
            <div className="flex">
              <Link to="/bottles">
                <TopMenuItem icon={faSearch} name="Trouver des bouteilles" />
              </Link>
            </div>
          </div>
          {isLoggedContext ? (
            <div className="flex justify-end">
              <div>
                <div className="flex flex-col rounded-xl h-10 p-2 m-1 justify-center ">
                  <div className="flex m-1 justify-center">
                    <div>
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </div>
                    <div className="ml-2">
                      <h1>{currentUser.username}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <Logout />
                </div>
              </div>
            </div>
          ) : (
            //
            <div className="flex justify-end">
              <div>
                <div onClick={showSigninModal}>
                  <TopMenuItem icon={faSign} name="SIGNIN" />
                </div>
              </div>
              <div>
                <div onClick={showSignupModal}>
                  <TopMenuItem icon={faAward} name="SIGNUP" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
