/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { user } from "../types/userType";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";

type IProps = {
  title: string;
  list: user[];
  resetThenSetDropList: (id: string, username: string) => void;
};

const TopMenuItem: React.FC<IProps> = (dropdown) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(dropdown.title);

  const userTargetRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);
  }, []);

  useEffect(() => {}, [isListOpen]);

  const checkIfClickedOutside = (event: MouseEvent) => {
    if (userTargetRef.current && !userTargetRef.current.contains(event.target as Node)) {
      setIsListOpen(false);
    }
  };

  const toggleList = () => {
    setIsListOpen(!isListOpen);
  };

  const selectItem = (user: user) => {
    const { id, username } = user;

    setIsListOpen(false);
    setTitle(user.username);
    dropdown.resetThenSetDropList(id, username);
  };

  return (
    <div
      id="dropMenuZone"
      ref={userTargetRef}
      className="absolute border-electric-violet-100h-10 text-xl p-1 outline-none  bg-electric-violet-200/10 hover:bg-electric-violet-600/95 border-0  m-2 rounded-xl ">
      <button type="button" className="" onClick={toggleList}>
        <div className="flex">
          <div className="mx-4 my-1 px-1 bg-robins-egg-blue-300 border-electric-violet-200/20 border-2 rounded-lg">
            {isListOpen ? <h1>^</h1> : <h1>v</h1>}
          </div>
          <div className="mx-4 my-1">{dropdown.title}</div>
        </div>
      </button>
      {isListOpen && (
        <div className="h-40 overflow-hidden overflow-y-scroll">
          <div role="list" className="flex flex-col">
            {dropdown.list.map((user) => (
              <button type="button" className="" key={uuidv4()} onClick={() => selectItem(user)}>
                <div>{user.username}</div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopMenuItem;
