import { TalkToUser, getCookie } from "../components/helper/TalkToUser";
import { bottle, cookieNickname } from "../types/bottleType";
import { user } from "../types/userType";
import { logout } from "./authService";

// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const throwNewBottle = async (newBottle: bottle, user: user) => {
  const response = await fetch(`${URL}/sendNewBottle`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN")
    },
    body: JSON.stringify(newBottle)
  });
  if (response.status === 401) {
    alert("Vous avez perdus les authorization pour continuer / Signin again !");
    logout(user);
    window.open("/", "_self");
  }

  if (!response.ok) {
    const data = await response.json();
    TalkToUser(
      `Votre Bouteille ne peut être ajoutée! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }

  try {
    const data = await response.json();
    TalkToUser("Votre Bouteille est envoyée ! / Parcourez l'onglet Trouver des bouteilles !");
    alert("Votre Bouteille est envoyée! / Parcourez l'onglet Trouver des bouteilles !");
    window.open("/bottles", "_self");
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getUserBottles = async (user: user) => {
  const response = await fetch(`${URL}/getUserBottles`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN")
    },
    body: JSON.stringify(user)
  });
  return response.json();
};

export const getPubBottles = async <bottle>(): Promise<bottle[]> => {
  const response = await fetch(`${URL}/publicBottles`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

//<------Cookie handling------>
export const getCookieNickname = async <cookieNickname>(): Promise<cookieNickname> => {
  const response = await fetch(`${URL}/getNicknameCook`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    const nickname = await response.json();
    return nickname;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const setNicknameCookie = async (newNickname: cookieNickname) => {
  const response = await fetch(`${URL}/setNicknameCook`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(newNickname)
  });

  if (!response.ok) {
    const data = await response.json();
    TalkToUser(
      `Votre nick ne peux être enregistrer ! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }
  try {
    const data = await response.json();
    TalkToUser("Nouveau Baptême ! WHOUO ! / Bienvenue " + data.nickname);
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};
