/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { getPubBottles, getUserBottles } from "../services/bottleService";
import { bottle } from "../types/bottleType";
import { v4 as uuidv4 } from "uuid";
import Bottle from "./Bottle";
import aimMainBg from "../images/headaimbottledcut.png";
import { AuthContext } from "../contexts/AuthProvider";
import { UserContextType } from "../types/userType";
import { UniqueLoaders } from "./class/UniqueLoaders";

type IProps = {
  loaders: UniqueLoaders;
};

const BottleList: React.FC<IProps> = ({ loaders }) => {
  const [bottles, setBottles] = useState<bottle[]>([]);

  const [bottle1, setBottle1] = useState<bottle[]>([]);

  const { currentUser } = useContext(AuthContext) as UserContextType;

  useEffect(() => {
    // const uniqueLoaderTmp = new UniqueLoaders();
    // setUniqueLoaders(uniqueLoaderTmp);
    if (currentUser.id !== "") {
      getBottles();
    } else {
      getPublicBottles();
    }
    return () => {
      setBottles([]); // Clean on unmount of comp
    };
  }, [currentUser]);

  const getBottles = async () => {
    try {
      const fetchedBottle: bottle[] = await getUserBottles(currentUser);
      setBottles(fetchedBottle);

      const newBottlesTmp: bottle[] = [];
      newBottlesTmp.push(fetchedBottle[0]);
      setBottle1(newBottlesTmp);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const getPublicBottles = async () => {
    try {
      const fetchedBottle: bottle[] = await getPubBottles();
      setBottles(fetchedBottle);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div className="flex rounded-3xl p-1 justify-center w-full">
      <div className="z-0 opacity-5 absolute">
        <img src={aimMainBg} alt="Head AimBottled" />
      </div>
      <div className="z-20 m-2 w-9/12 border-2 rounded-3xl drop-shadow-2b">
        {/* <div className="flex  text-white border-2 border-robins-egg-blue-900 hover:border-electric-violet-200 flex-row rounded-3xl p-1 m-2  bg-robins-egg-blue-600/40">
          <h2 className=" text-lge w-2/12">ID bouteille :</h2>
          <h2 className=" w-3/12">Titre :</h2>
          <h2 className=" w-3/12">UserTarget :</h2>
          <h2 className=" w-7/12">Message :</h2>
        </div> */}
        <div className="flex p-8 flex-col rounded-3xl  bg-robins-egg-blue-600/40">
          {bottles.map((bottle: bottle) => (
            <Bottle key={uuidv4()} bottle={bottle} loaders={loaders} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottleList;
