// import { TalkToUser } from "../components/helper/TalkToUser";
import { getCookie, TalkToUser } from "../components/helper/TalkToUser";
import { user } from "../types/userType";

// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const login = async (username: string, password: string) => {
  const response = await fetch(`${URL}/user/signin`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  });

  const data = await response.json();

  if (response.status === 429) {
    TalkToUser(
      `Trop de Requêtes  !!! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }

  if (!response.ok) {
    TalkToUser(
      `Mauvaise combinaison - NO GOOD ! !! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }
  if (data.username) {
    localStorage.setItem("aimUsername", data.username);
    localStorage.setItem("aimUserid", data.id);
    // window.open("/", "_self");
  }
  return data;
};

export const signup = async (newUser: user) => {
  const response = await fetch(`${URL}/user/signup`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(newUser)
  });

  const data = await response.json();

  if (!response.ok) {
    TalkToUser(
      `vos champs sont mal remplis !! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }

  if (data.username) {
    localStorage.setItem("aimUsername", data.username);
    localStorage.setItem("aimUserid", data.id);
    // window.open("/", "_self");
  }
  return data;
};

export const getUserByUsername = async <user>(username: string, userid: string): Promise<user> => {
  const response = await fetch(`${URL}/user/searchUsername`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN")
    },
    body: JSON.stringify({ username: username, userId: userid })
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  try {
    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getAllUsers = async (username: string, userid: string): Promise<user[]> => {
  const response = await fetch(`${URL}/user/searchAllUsers`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN")
    },
    body: JSON.stringify({ username: username, userId: userid })
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  try {
    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};
