import * as THREE from "three";
import { CubeTexture, Mesh } from "three";
import { TextBufferGeometry, TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { bottle } from "../../types/bottleType";
import { UniqueLoaders } from "./UniqueLoaders";
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export class SideBottleScene {
  canvas: HTMLCanvasElement;
  divXpand: HTMLDivElement;
  isBottleExpand: boolean;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderWidth: number;
  renderHeight: number;
  width: number;
  height: number;
  renderer: THREE.WebGLRenderer;
  // ambiantLight: THREE.AmbientLight;

  fullScreenMode: boolean;

  //lights
  spotLight: THREE.SpotLight;
  hemiLight: THREE.HemisphereLight;
  dirLight: THREE.DirectionalLight;

  //cubemap
  cubeMapLoader: THREE.CubeTextureLoader;
  cubeMap: CubeTexture;
  skyBox: Mesh;

  //loader
  loaders: UniqueLoaders;

  orbitControl: OrbitControls;

  bottle: bottle;

  //glft Bottle
  bottleModel: THREE.Mesh;
  bottleModelMateriel: THREE.MeshPhysicalMaterial;
  sphereColorTexture: THREE.Texture;

  //text 3d
  colorTextTitle: THREE.Texture;
  materialtxt: THREE.MeshPhysicalMaterial;
  titleFont: Font;
  textGeoTitle: TextBufferGeometry | any;
  meshTextTitle: THREE.Mesh;

  constructor(
    canvas: HTMLCanvasElement,
    divCanvas: HTMLDivElement,
    divWideBottle: HTMLDivElement,
    bottle: bottle,
    loaders: UniqueLoaders
  ) {
    this.renderWidth = divCanvas.offsetWidth;
    this.renderHeight = divCanvas.offsetHeight;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(60, this.renderWidth / this.renderHeight, 1, 5000);
    this.canvas = canvas;

    this.bottle = bottle;

    this.divXpand = divWideBottle;
    this.isBottleExpand = false;
    this.width = divCanvas.offsetWidth / 4;
    this.height = divCanvas.offsetHeight / 4;
    this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true, alpha: true });
    this.fullScreenMode = false;

    this.camera.position.set(0, 0, 400);

    this.renderer.setSize(this.renderWidth, this.renderHeight);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.camera.updateProjectionMatrix();

    this.loaders = loaders;

    this.hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    this.hemiLight.position.set(0, 200, 0);
    this.scene.add(this.hemiLight);

    this.dirLight = new THREE.DirectionalLight(0xffffff);
    this.dirLight.position.set(75, 200, -75);
    this.scene.add(this.dirLight);

    this.spotLight = new THREE.SpotLight(0xffffff, 1);
    this.spotLight.castShadow = true;
    this.spotLight.position.set(0, 0, 200);
    this.scene.add(this.spotLight);

    //cubemap
    this.cubeMapLoader = new THREE.CubeTextureLoader();
    this.cubeMap = new CubeTexture();
    this.skyBox = new Mesh();

    //glft bottle
    this.bottleModel = new THREE.Mesh();
    this.bottleModelMateriel = new THREE.MeshPhysicalMaterial();

    const rndtexture = this.bottle.scene.texture;
    const modelToLoad = this.bottle.scene.bottleModel;
    this.sphereColorTexture = this.loaders.textureLoaderTextTitle.load(
      `/textures/bottle${modelToLoad}/color${rndtexture}.jpg`
    );
    this.loadCubeMap();
    this.loadBottle();

    //textBottle
    this.colorTextTitle = new THREE.Texture();
    this.materialtxt = new THREE.MeshPhysicalMaterial();
    this.titleFont = new Font({});
    this.load_txt_fonts();
    this.textGeoTitle = {};
    this.meshTextTitle = new Mesh();

    //event handler
    this.listenerHandling(divCanvas);

    this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
  }

  tick = () => {
    this.loaders.elapsedTime = this.loaders.clock.getElapsedTime() as number;
    this.renderer.render(this.scene, this.camera);
    window.requestAnimationFrame(this.tick);
    this.bottleModel.rotation.y = -this.loaders.elapsedTime;
    // this.skyBox.rotation.z = this.loaders.elapsedTime / 5;

    this.orbitControl.update();
    // this.bottleModel.rotation.x = Math.sin(-this.loaders.elapsedTime);
  };

  async resizeRendererOnChangeDivCanvas() {}

  async listenerHandling(divCanvas: HTMLDivElement) {
    //Resize window function
    let camAdjust = 0;
    let OldOffsetWidht = divCanvas.offsetWidth;
    window.addEventListener("resize", () => {
      // Update sizes
      this.renderWidth = divCanvas.offsetWidth;
      this.renderHeight = divCanvas.offsetHeight;

      // Update camera on resize
      camAdjust = divCanvas.offsetWidth - OldOffsetWidht;
      this.camera.position.z = this.camera.position.z - camAdjust;
      this.camera.aspect = this.renderWidth / this.renderHeight;
      this.camera.updateProjectionMatrix();
      OldOffsetWidht = divCanvas.offsetWidth;

      this.orbitControl.update();

      // Update renderer
      if (!this.fullScreenMode) {
        this.renderer.setSize(this.renderWidth, this.renderHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      }
    });

    this.canvas.addEventListener("dblclick", () => {
      if (!document.fullscreenElement) {
        this.fullScreenMode = true;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.camera.updateProjectionMatrix();
        this.canvas.requestFullscreen();
        this.orbitControl.update();
      } else {
        document.exitFullscreen();
        this.fullScreenMode = false;
        this.renderer.setSize(this.renderWidth, this.renderHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.camera.updateProjectionMatrix();
        this.orbitControl.update();
      }
    });

    //Longue fonction qui permet au renderer un resize smooth
    this.divXpand.addEventListener("click", () => {
      if (!this.isBottleExpand) {
        gsap
          .to(divCanvas, {
            duration: 0.02,
            delay: 0,
            width: divCanvas.offsetWidth + 12,
            height: divCanvas.offsetHeight + 15
          })
          .then(() => {
            this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
          })
          .then(() => {
            gsap
              .to(divCanvas, {
                duration: 0.02,
                delay: 0,
                width: divCanvas.offsetWidth + 12,
                height: divCanvas.offsetHeight + 15
              })
              .then(() => {
                this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
              })
              .then(() => {
                gsap
                  .to(divCanvas, {
                    duration: 0.02,
                    delay: 0,
                    width: divCanvas.offsetWidth + 12,
                    height: divCanvas.offsetHeight + 15
                  })
                  .then(() => {
                    this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                  })
                  .then(() => {
                    gsap
                      .to(divCanvas, {
                        duration: 0.02,
                        delay: 0,
                        width: divCanvas.offsetWidth + 12,
                        height: divCanvas.offsetHeight + 15
                      })
                      .then(() => {
                        this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                      })
                      .then(() => {
                        gsap
                          .to(divCanvas, {
                            duration: 0.02,
                            delay: 0,
                            width: divCanvas.offsetWidth + 12,
                            height: divCanvas.offsetHeight + 15
                          })
                          .then(() => {
                            this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                          })
                          .then(() => {
                            gsap
                              .to(divCanvas, {
                                duration: 0.02,
                                delay: 0,
                                width: divCanvas.offsetWidth + 12,
                                height: divCanvas.offsetHeight + 15
                              })
                              .then(() => {
                                this.renderer.setSize(
                                  divCanvas.offsetWidth,
                                  divCanvas.offsetHeight
                                );
                              })
                              .then(() => {
                                gsap
                                  .to(divCanvas, {
                                    duration: 0.02,
                                    delay: 0,
                                    width: divCanvas.offsetWidth + 12,
                                    height: divCanvas.offsetHeight + 15
                                  })
                                  .then(() => {
                                    this.renderer.setSize(
                                      divCanvas.offsetWidth,
                                      divCanvas.offsetHeight
                                    );
                                  })
                                  .then(() => {
                                    gsap
                                      .to(divCanvas, {
                                        duration: 0.02,
                                        delay: 0,
                                        width: divCanvas.offsetWidth + 12,
                                        height: divCanvas.offsetHeight + 15
                                      })
                                      .then(() => {
                                        this.renderer.setSize(
                                          divCanvas.offsetWidth,
                                          this.renderHeight
                                        );
                                      })
                                      .then(() => {
                                        gsap
                                          .to(divCanvas, {
                                            duration: 0.02,
                                            delay: 0,
                                            width: divCanvas.offsetWidth + 12,
                                            height: divCanvas.offsetHeight + 15
                                          })
                                          .then(() => {
                                            this.renderer.setSize(
                                              divCanvas.offsetWidth,
                                              this.renderHeight
                                            );
                                          })
                                          .then(() => {
                                            gsap
                                              .to(divCanvas, {
                                                duration: 0.02,
                                                delay: 0,
                                                width: divCanvas.offsetWidth + 12,
                                                height: divCanvas.offsetHeight + 15
                                              })
                                              .then(() => {
                                                this.renderer.setSize(
                                                  divCanvas.offsetWidth,
                                                  divCanvas.offsetHeight
                                                );
                                                this.renderer.setPixelRatio(
                                                  Math.min(window.devicePixelRatio, 2)
                                                );
                                              });
                                            this.isBottleExpand = true;
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      }
      if (this.isBottleExpand) {
        gsap
          .to(divCanvas, {
            duration: 0.02,
            delay: 0,
            width: divCanvas.offsetWidth - 12,
            height: divCanvas.offsetHeight - 15
          })
          .then(() => {
            this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
          })
          .then(() => {
            gsap
              .to(divCanvas, {
                duration: 0.02,
                delay: 0,
                width: divCanvas.offsetWidth - 12,
                height: divCanvas.offsetHeight - 15
              })
              .then(() => {
                this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
              })
              .then(() => {
                gsap
                  .to(divCanvas, {
                    duration: 0.02,
                    delay: 0,
                    width: divCanvas.offsetWidth - 12,
                    height: divCanvas.offsetHeight - 15
                  })
                  .then(() => {
                    this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                  })
                  .then(() => {
                    gsap
                      .to(divCanvas, {
                        duration: 0.02,
                        delay: 0,
                        width: divCanvas.offsetWidth - 12,
                        height: divCanvas.offsetHeight - 15
                      })
                      .then(() => {
                        this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                      })
                      .then(() => {
                        gsap
                          .to(divCanvas, {
                            duration: 0.02,
                            delay: 0,
                            width: divCanvas.offsetWidth - 12,
                            height: divCanvas.offsetHeight - 15
                          })
                          .then(() => {
                            this.renderer.setSize(divCanvas.offsetWidth, divCanvas.offsetHeight);
                          })
                          .then(() => {
                            gsap
                              .to(divCanvas, {
                                duration: 0.02,
                                delay: 0,
                                width: divCanvas.offsetWidth - 12,
                                height: divCanvas.offsetHeight - 15
                              })
                              .then(() => {
                                this.renderer.setSize(
                                  divCanvas.offsetWidth,
                                  divCanvas.offsetHeight
                                );
                              })
                              .then(() => {
                                gsap
                                  .to(divCanvas, {
                                    duration: 0.02,
                                    delay: 0,
                                    width: divCanvas.offsetWidth - 12,
                                    height: divCanvas.offsetHeight - 15
                                  })
                                  .then(() => {
                                    this.renderer.setSize(
                                      divCanvas.offsetWidth,
                                      divCanvas.offsetHeight
                                    );
                                  })
                                  .then(() => {
                                    gsap
                                      .to(divCanvas, {
                                        duration: 0.02,
                                        delay: 0,
                                        width: divCanvas.offsetWidth - 12,
                                        height: divCanvas.offsetHeight - 15
                                      })
                                      .then(() => {
                                        this.renderer.setSize(
                                          divCanvas.offsetWidth,
                                          this.renderHeight
                                        );
                                      })
                                      .then(() => {
                                        gsap
                                          .to(divCanvas, {
                                            duration: 0.02,
                                            delay: 0,
                                            width: divCanvas.offsetWidth - 12,
                                            height: divCanvas.offsetHeight - 15
                                          })
                                          .then(() => {
                                            this.renderer.setSize(
                                              divCanvas.offsetWidth,
                                              this.renderHeight
                                            );
                                          })
                                          .then(() => {
                                            gsap
                                              .to(divCanvas, {
                                                duration: 0.02,
                                                delay: 0,
                                                width: divCanvas.offsetWidth - 12,
                                                height: divCanvas.offsetHeight - 15
                                              })
                                              .then(() => {
                                                this.renderer.setSize(
                                                  divCanvas.offsetWidth,
                                                  divCanvas.offsetHeight
                                                );
                                                this.renderer.setPixelRatio(
                                                  Math.min(window.devicePixelRatio, 2)
                                                );
                                              });
                                            this.isBottleExpand = false;
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      }

      this.camera.updateProjectionMatrix();
    });

    this.canvas.addEventListener("mouseover", () => {
      gsap.to(this.camera.position, { duration: 0.5, delay: 0, z: this.camera.position.z - 150 });
      gsap.to(this.bottleModel.rotation, { duration: 0.5, delay: 0, z: 0.3 });
      this.camera.updateProjectionMatrix();
      this.orbitControl.update();
    });
    this.canvas.addEventListener("mouseout", () => {
      gsap.to(this.camera.position, { duration: 0.5, delay: 0, z: this.camera.position.z + 150 });
      gsap.to(this.bottleModel.rotation, { duration: 0.5, delay: 0, z: 0 });
      this.camera.updateProjectionMatrix();
      this.orbitControl.update();
    });

    // eslint-disable-next-line no-unused-vars
    // window.addEventListener("keydown", (keydown) => {
    //   // this.destroy();
    //   console.log(keydown);
    // });

    // this.camera.updateProjectionMatrix();
    // this.orbitControl.update();
  }

  load_txt_fonts() {
    this.colorTextTitle = this.loaders.textureLoaderTextTitle.load("/textures/color.png");
    this.materialtxt.map = this.colorTextTitle;
    this.materialtxt.metalness = 0.7;
    this.materialtxt.roughness = 0.5;
    this.loaders.fontLoader.load("/fonts/helvetiker_regular.typeface.json", (font: Font) => {
      this.titleFont = font;
      this.showTitle();
    });
  }

  loadCubeMap() {
    const cubeToLoad = this.bottle.scene.skybox;
    this.cubeMap = this.loaders.cubeMapArray[cubeToLoad];
    this.scene.background = this.cubeMap; //WORKING VERSION

    this.scene.add(this.loaders.skyBox[cubeToLoad]); //ALWAYS LOAD THE SAME
  }

  async loadBottle() {
    const modelToLoad = this.bottle.scene.bottleModel;
    this.bottleModel = this.loaders.bottleModelArrray[modelToLoad].clone();
    this.bottleModel.position.setY(-60);
    this.bottleModel.scale.set(
      this.bottle.scene.scaleBottleX * this.bottleModel.scale.x,
      this.bottle.scene.scaleBottleY * this.bottleModel.scale.y,
      this.bottle.scene.scaleBottleZ * this.bottleModel.scale.z
    );

    // this.bottleModel.material = this.bottleModelMateriel;
    this.loaders.bottleModelMateriel.metalness = this.bottle.scene.metalness;
    this.loaders.bottleModelMateriel.roughness = this.bottle.scene.roughness;
    this.loaders.bottleModelMateriel.envMap = this.cubeMap;
    // // // this.bottleModelMateriel.displacementScale = 15;
    // // // this.bottleModelMateriel.displacementMap = this.sphereHeightTexture;
    this.loaders.bottleModelMateriel.map = this.sphereColorTexture;
    this.loaders.bottleModelMateriel.opacity = this.bottle.scene.opacity;
    this.loaders.bottleModelMateriel.transparent = true;
    this.loaders.bottleModelMateriel.needsUpdate = true;
    this.loaders.bottleModelMateriel.side = THREE.DoubleSide;
    this.bottleModel.material = this.loaders.bottleModelMateriel.clone();
    // // this.bottleModelMateriel.alphaMap = this.sphereAlphaTexture;

    this.scene.add(this.bottleModel);
  }

  showTitle() {
    this.textGeoTitle = new TextGeometry(this.bottle.title || "", {
      font: this.titleFont,
      size: 8,
      height: 1,
      curveSegments: 20,
      bevelEnabled: true,
      bevelThickness: 1,
      bevelSize: 0,
      bevelOffset: 0,
      bevelSegments: 0
    });
    this.textGeoTitle.center();
    this.meshTextTitle = new THREE.Mesh(this.textGeoTitle, this.materialtxt);
    this.meshTextTitle.position.z = 100;
    this.meshTextTitle.position.y = 35;
    // textMeshTitle.rotateY(-0.3);
    this.scene.add(this.meshTextTitle);
  }
  // destructor() {
  //   // This function is called when the object is destroyed
  //   this.scene.traverse((o: any) => {
  //     if (o.geometry) {
  //       o.geometry.dispose();
  //       // console.log("dispose geometry ", o.geometry)
  //     }
  //     if (o.texture) {
  //       o.texture.dispose();
  //       // console.log("dispose geometry ", o.geometry)
  //     }
  //     if (o.material) {
  //       if (o.material.length) {
  //         for (let i = 0; i < o.material.length; ++i) {
  //           o.material[i].dispose();
  //           // console.log("dispose material ", o.material[i])
  //         }
  //       } else {
  //         o.material.dispose();
  //         // console.log("dispose material ", o.material)
  //       }
  //     }
  //   });

  //   this.renderer.dispose();
  //   this.renderer.forceContextLoss();
  //   console.log("DESTROYED!");
  // }

  destroy = async () => {
    //plusieurs test mais ceci simple reste sans mem leak et sans erreur

    // TRAVERSING ALL OBJECTS IN THE SCENE
    // if (this.renderer.getContextAttributes() !== null) {
    // this.scene.traverse((o: any) => {
    //   if (o.geometry) {
    //     o.geometry.dispose();
    //     // console.log("dispose geometry ", o.geometry)
    //   }
    //   if (o.texture) {
    //     o.texture.dispose();
    //     // console.log("dispose geometry ", o.geometry)
    //   }
    //   if (o.material) {
    //     if (o.material.length) {
    //       for (let i = 0; i < o.material.length; ++i) {
    //         o.material[i].dispose();
    //         // console.log("dispose material ", o.material[i])
    //       }
    //     } else {
    //       o.material.dispose();
    //       // console.log("dispose material ", o.material)
    //     }
    //   }
    // });

    // this.renderer.dispose();
    this.renderer.forceContextLoss();
    // }
  };
}
