import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import TopMenu from "./components/TopMenu";
import React, { useContext, useEffect, useState } from "react";
import Accueil from "./components/Accueil";
import BottleThrow from "./components/BottleThrow";
import BottleList from "./components/BottleList";
import { v4 as uuidv4 } from "uuid";
import CookieNickname from "./components/CookieNickname";
import { AuthContext } from "./contexts/AuthProvider";
import { UserContextType } from "./types/userType";
import { UniqueLoaders } from "./components/class/UniqueLoaders";

const App: React.FC = () => {
  const [menuState, setMenuState] = useState<boolean>(
    JSON.parse(localStorage.getItem("showMenuAimbottled") as string)
  );
  const [uniqueLoaders] = useState<UniqueLoaders>(new UniqueLoaders());

  const { isLoggedContext } = useContext(AuthContext) as UserContextType;

  // <------Loading pref from LocalStorage------>
  useEffect(() => {
    if (localStorage.getItem("showMenuAimbottled") === "false") {
      setMenuState(false);
    } else {
      setMenuState(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <div className="flex">
          <div
            id="toast"
            className="fixed hidden items-center z-40 top-2/4 left-3/4 text-white p-2 border-2 hover:bg-electric-violet-900/30 bg-electric-violet-900/50 rounded-xl  hover:text-white">
            <div id="message">
              Message to be deliver, lorem ipsum.. Message to be deliver, lorem ipsum.. Message to
              be deliver, lorem ipsum.. Message to be deliver, lorem ipsum..
            </div>
            <div className="flex justify-center">
              <div className="flex justify-center border-2 w-20 rounded-md text-2xl p-1 hover:cursor-pointer hover:bg-electric-violet-300">
                Got it!
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-auto bg-robins-egg-blue-700">
          <TopMenu key={uuidv4()} menuState={menuState} />
        </div>
        {isLoggedContext ? (
          <div className="flex flex-col bg-gradient-to-b from-robins-egg-blue-700">
            <div>
              <CookieNickname key={uuidv4()} />
            </div>
            <div className="">
              <Routes>
                <Route path="/" element={<Accueil />} />
                <Route path="/accueil" element={<Accueil />} />
                <Route path="/bottleThrow" element={<BottleThrow loaders={uniqueLoaders} />} />
                <Route path="/bottles" element={<BottleList loaders={uniqueLoaders} />} />
              </Routes>
            </div>
          </div>
        ) : (
          <div className="flex bg-gradient-to-b from-robins-egg-blue-700">
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/bottles" element={<BottleList loaders={uniqueLoaders} />} />
              {/* <Route path="/bottles" element={<BottleList />} /> */}
            </Routes>
          </div>
        )}
      </div>
    </Router>
  );
};
export default App;
