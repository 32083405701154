/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { bottle } from "../types/bottleType";
import { parseMsToDateString } from "./helper/TalkToUser";
import SideBottle from "./SideBottle";
import { v4 as uuidv4 } from "uuid";
import { UniqueLoaders } from "./class/UniqueLoaders";

type IProps = {
  bottle: bottle;
  loaders: UniqueLoaders;
};
const Bottle: React.FC<IProps> = ({ bottle, loaders }) => {
  const [bottleState] = useState<bottle>(bottle);

  useEffect(() => {}, []);

  return (
    <div className="flex text-xs justify-center text-white/20 hover:text-white m-1 ">
      <div className="w-1/3 border-2 rounded-3xl">
        <SideBottle key={uuidv4()} bottle={bottle} loaders={loaders}></SideBottle>
      </div>

      <div className="text-center border-2 w-full border-robins-egg-blue-900 rounded-xl  hover:border-electric-violet-200  cursor-default drop-shadow-lg hover:drop-shadow-1b">
        <div className="flex justify-center">
          <div className="text-4xl px-6 py-2">
            <p className="font-extrabold font-yeseva">{bottleState.title}</p>
          </div>
        </div>

        <div className="flex justify-between border-b-[1px] border-dotted border-robins-egg-blue-900 hover:border-electric-violet-50">
          <div className="px-6 py-2">
            <h1>thrower : </h1>
          </div>
          <div className="px-6 py-2 font-extrabold font-yeseva">{bottleState.userName}</div>
        </div>

        <div className="flex justify-between border-b-[1px] border-dotted border-robins-egg-blue-900 hover:border-electric-violet-50">
          <div className="px-6 py-2">
            <h1>Target : </h1>
          </div>
          <div className="px-6 py-2">{bottleState.userTargetName}</div>
        </div>

        <div className="flex justify-between border-b-[1px] border-dotted border-robins-egg-blue-900 hover:border-electric-violet-50">
          <div className="px-6 py-2">
            <h1>Created : </h1>
          </div>
          <div className="px-6 py-2">{parseMsToDateString(bottleState.createdAt)}</div>
        </div>

        <div className="flex justify-between border-b-[1px] border-dotted border-robins-egg-blue-900 hover:border-electric-violet-50">
          <div className="px-6 py-2">
            <h1>Unlock : </h1>
          </div>
          <div className="px-6 py-2">{parseMsToDateString(bottleState.unlockTime)}</div>
        </div>

        <div className="flex justify-between">
          <div className="px-6 py-2">
            <p>message:</p>
          </div>
        </div>

        <div className="flex justify-between rounded-lg m-2 border-[1px] border-dotted  border-robins-egg-blue-900 hover:border-electric-violet-50">
          <div className="px-6 py-2">
            <p>{bottleState.message}</p>
          </div>
        </div>
      </div>
      <div className="w-1/3"></div>
    </div>
  );
};

export default Bottle;
