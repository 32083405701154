import React, { useContext, useState } from "react";
import { TalkToUser } from "./helper/TalkToUser";
import { user } from "../types/userType";
import { signup } from "../services/userService";
import { AuthContext } from "../contexts/AuthProvider";
import { UserContextType } from "../types/userType";

type IProps = {
  handleClose: () => void;
  showModal: boolean;
};

const Signup: React.FC<IProps> = ({ handleClose }) => {
  const filler: any = {};
  const newUserTmp: user = {
    ...filler
  };

  const { updateUser } = useContext(AuthContext) as UserContextType;
  const [newUser, setUser] = useState<user>(newUserTmp);

  const checkAllForm = (): boolean => {
    let formAllGood = true;
    if (
      newUser.username === undefined ||
      newUser.firstName === undefined ||
      newUser.lastName === undefined ||
      newUser.email === undefined ||
      newUser.telephone === undefined ||
      newUser.password === undefined
    ) {
      formAllGood = false;
    }
    return formAllGood;
  };

  const setNewUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userTmp = { ...newUser, [event.target.id]: event.target.value };
    //  <------FORM VALIDATION FRONT END------>
    if (!event.target.validity.valueMissing) {
      setUser(userTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
    if (!event.target.validity.patternMismatch) {
      setUser(userTmp);
    } else {
      TalkToUser(
        `Vos champs sont mal remplis !
        USERNAME : 7 - 29 char 
        PASS : min 8 char 
        NAME and PRENAME: min 3 char 
        EMAIL : xx@xx.xx 
        PHONE : 123-123-1234 - (123) 123-1323`
      );
    }
  };

  const sendSub = async () => {
    if (checkAllForm()) {
      try {
        const loggedUser = await signup(newUser);
        updateUser(loggedUser);
      } catch (error) {
        TalkToUser(error as string);
      }
    } else {
      TalkToUser("Svp aucun champs vide...aucun aucun !");
    }
  };

  return (
    <div className="fixed top-1/4 rounded-2xl opacity-100 z-40">
      <div className="rounded-lg hover:bg-robins-egg-blue-400/100 bg-robins-egg-blue-400/80 border-4 text-electric-violet-50">
        <div className="text-4xl flex justify-between text-robins-egg-blue-800 border-2 rounded-xl">
          <div>
            <h1> </h1>
          </div>
          <div className="m-2">
            <h1>SIGNUP</h1>
          </div>
          <div onClick={handleClose} className="m-2  cursor-pointer">
            <h1>X</h1>
          </div>
        </div>
        <form>
          <div className="flex justify-center border-2 rounded-3xl  hover:text-robins-egg-blue-900/50">
            <div className="flex m-4">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">username:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"username"}
                  onChange={setNewUser}
                  type="text"
                  pattern="^[A-Za-z][A-Za-z0-9_]{7,29}$"
                  name="username"
                  placeholder={"username"}
                  defaultValue={newUser.username}
                  required
                />
              </div>
            </div>
            <div className="flex m-4 ">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">Password:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"password"}
                  onChange={setNewUser}
                  type="password"
                  name="password"
                  pattern="\w{9,}"
                  placeholder={"password"}
                  defaultValue={newUser.password}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end flex-col border-2 rounded-3xl  hover:text-robins-egg-blue-900/50">
            <div className="flex m-4">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">firstName:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"firstName"}
                  onChange={setNewUser}
                  pattern="\w{3,}"
                  type="text"
                  name="firstName"
                  placeholder={"firstName"}
                  defaultValue={newUser.firstName}
                  required
                />
              </div>
            </div>
            <div className="flex m-4 ">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">lastName:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"lastName"}
                  onChange={setNewUser}
                  pattern="\w{3,}"
                  type="text"
                  name="lastName"
                  placeholder={"lastName"}
                  defaultValue={newUser.lastName}
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-row border-2 rounded-3xl ">
            <div className="flex m-4">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">email:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"email"}
                  onChange={setNewUser}
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  name="email"
                  placeholder={"email"}
                  defaultValue={newUser.email}
                  required
                />
              </div>
            </div>
            <div className="flex m-4 ">
              <div className="flex justify-center p-1 m-2">
                <label className="m-2">telephone:</label>
              </div>
              <div>
                <input
                  className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-800/10 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                  id={"telephone"}
                  onChange={setNewUser}
                  type="tel"
                  pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                  name="telephone"
                  placeholder={"telephone"}
                  defaultValue={newUser.telephone}
                  required
                />
              </div>
            </div>
          </div>
        </form>
        <div
          onClick={sendSub}
          className="flex m-4 justify-center border-2 p-2 mt-8 hover:bg-electric-violet-200/60 rounded-xl  hover:text-robins-egg-blue-900/50">
          <button>submit</button>
        </div>
      </div>
    </div>
  );
};

export default Signup;
