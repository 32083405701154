import React from "react";
import aimMainBg from "../images/aimmainbig.png";

const Accueil: React.FC = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="z-20 p-4 text-6xl text-white">Oubliez les bouteilles à la mer !</div>
      <div className="z-20 px-6 text-2xl text-robins-egg-blue-50">
        jeter vos message temporel dans le Web
      </div>
      <div className="z-20 px-6 text-xl text-robins-egg-blue-100">Avec ou sans destinataire...</div>
      <div className="z-20 px-6 text-xl text-robins-egg-blue-200">
        Choisissez un temps de derive...
      </div>
      <div className="z-20 px-6 text-xl text-robins-egg-blue-300">dans 30 secondes...</div>
      <div className="z-20 px-6 text-xl text-robins-egg-blue-400">ou dans 300 ans !</div>
      <div className="z-0 opacity-40 absolute object-contain w-auto">
        <img src={aimMainBg} alt="Head AimBottled" />
      </div>
    </div>
  );
};

export default Accueil;
