import React, { useContext, useEffect, useState } from "react";
import { getCookieNickname, setNicknameCookie } from "../services/bottleService";
import { cookieNickname } from "../types/bottleType";
import { TalkToUser } from "./helper/TalkToUser";
import { AuthContext } from "../contexts/AuthProvider";
import { UserContextType } from "../types/userType";

const CookieNickname: React.FC = () => {
  const [newNick, setNewNick] = useState<cookieNickname>({ nickname: "John Smith" });
  const { currentUser } = useContext(AuthContext) as UserContextType;

  const setNickOnchange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let nick = event.target.value;
    setNewNick({ ...newNick, nickname: nick });
  };

  useEffect(() => {
    getOldNick();
    return () => {
      setNewNick({ nickname: "" }); // Clean on unmount of comp
    };
  }, []);

  useEffect(() => {
    getOldNick();

    return () => {
      setNewNick({ nickname: "" }); // Clean on unmount of comp
    };
  }, [currentUser]);

  const sendSub = (): void => {
    try {
      setNicknameCookie(newNick);
    } catch (error) {
      TalkToUser(error as string);
    }
  };

  const getOldNick = async () => {
    try {
      const fetchedCookieNick: cookieNickname = await getCookieNickname();
      if (fetchedCookieNick.nickname) {
        setNewNick(fetchedCookieNick);
      } else {
        if (currentUser.username) {
          setNewNick({ nickname: currentUser.username });
        }
      }
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div className="flex flex-col justify-top rounded-2xl opacity-100 w-full border-0 h-14 z-40">
      <div className="flex flex-row justify-center">
        <div className="flex justify-center p-1 m-2">
          <h2>Bonjour </h2>
        </div>
        <div>
          <input
            className=" p-1 w-32 outline-none valid:bg-electric-violet-200/5 valid:border-0   bg-white  m-2 rounded-xl"
            id={"nickname"}
            value={newNick.nickname}
            onChange={setNickOnchange}
            type="text"
            name="nickname"
            required
          />
        </div>
        <div className="flex justify-center p-1 m-2">
          <h2>Si tu veux un nom teporaire dit le-moi !! </h2>
        </div>
        <div
          onClick={sendSub}
          className="flex justify-center border-2 p-1 hover:bg-electric-violet-200/60 rounded-xl  hover:text-white">
          <button>Change ça !</button>
        </div>
      </div>
    </div>
  );
};

export default CookieNickname;
