import { getCookie, TalkToUser } from "../components/helper/TalkToUser";
import { user } from "../types/userType";
import { getUserByUsername } from "./userService";

export default async function isLogged() {
  const username = localStorage.getItem("aimUsername");
  const userid = localStorage.getItem("aimUserid");
  const filler: any = {};
  let authUser: user = {
    ...filler
  };
  if (localStorage.getItem("aimUsername")) {
    authUser = await getUserByUsername(username as string, userid as string);
    if (authUser.username === username && authUser.id === userid) {
      return true;
    }
  }
  return false;
}

export async function logout(user: user) {
  const URL = process.env.REACT_APP_API_BASE_URL;
  localStorage.removeItem("aimUsername");
  localStorage.removeItem("aimUserid");

  const response = await fetch(`${URL}/logout`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN")
    },
    body: JSON.stringify(user)
  });

  const data = await response.json();
  if (!response.ok) {
    TalkToUser(
      `Impossible de vous déconnecter - Status :  ${response.status}, ${response.statusText}`
    );
    throw new Error(data.message);
  }

  window.open("/", "_self");
}

export async function hardLogout() {
  const URL = process.env.REACT_APP_API_BASE_URL;
  localStorage.removeItem("aimUsername");
  localStorage.removeItem("aimUserid");

  const response = await fetch(`${URL}/hardLogout`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });

  const data = await response.json();
  if (!response.ok) {
    TalkToUser(
      `Déconnection en dur vous êtes en violation de sécurité - Status :  ${response.status}, ${response.statusText}`
    );
    throw new Error(data.message);
  }

  window.open("/", "_self");
}
