/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext, useRef } from "react";
import { throwNewBottle } from "../services/bottleService";
import { bottle } from "../types/bottleType";
import aimMainBg from "../images/headaimbottledcut.png";
import { TalkToUser } from "./helper/TalkToUser";
import { AuthContext } from "../contexts/AuthProvider";
import { user, UserContextType } from "../types/userType";
import isLogged, { hardLogout } from "../services/authService";
// import { useRef } from "react";
import DropList from "./DropList";
import { getAllUsers } from "../services/userService";
import Bottle from "./Bottle";
import { v4 as uuidv4 } from "uuid";
import { UniqueLoaders } from "./class/UniqueLoaders";
import * as THREE from "three";

type IProps = {
  loaders: UniqueLoaders;
};

const BottleThrow: React.FC<IProps> = ({ loaders }) => {
  const filler: any = {};
  const newBottleTmp: bottle = {
    ...filler,
    lockTime: 0,
    scene: {
      scaleBottleX: 1,
      scaleBottleY: 1,
      scaleBottleZ: 1,
      skybox: 4,
      texture: 0,
      opacity: 0.7,
      metalness: 0.7,
      roughness: 0,
      skyOpacity: 0.3,
      bottleModel: 0
    }
  };

  const [newBottle, setnewBottle] = useState<bottle>(newBottleTmp);
  const [userList, setUserList] = useState<user[]>([]);
  const [userListHead, setUserListHead] = useState<string>("PublicBottle");
  const { currentUser } = useContext(AuthContext) as UserContextType;
  const refInputLock = useRef<HTMLInputElement>(null);

  // const userTargetRef = useRef<HTMLInputElement>(null);

  const checkAllForm = (): boolean => {
    let formAllGood = true;
    if (
      newBottle._id === undefined ||
      newBottle.title === undefined ||
      newBottle.message === undefined
    ) {
      formAllGood = false;
    }
    return formAllGood;
  };

  const calculDateAsync = async (date: number): Promise<number> => {
    return date;
  };

  const setNewBottle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const lockFromref: number = parseInt(refInputLock.current?.value || "0");
    const bottleTmp = {
      ...newBottle,
      [event.target.id]: event.target.value,
      createdAt: Date.now(),
      unlockTime: await calculDateAsync(+Date.now() + +(lockFromref * 60 * 60 * 1000)),
      userId: currentUser.id,
      userName: currentUser.username
    };

    bottleTmp.createdAt = Date.now();
    // bottleTmp.unlockTime = +Date.now() + +(newBottle.lockTime * 60 * 60 * 1000); //heure en ms
    //  <------FORM VALIDATION FRONT END------>
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const setNewBottleScaleX = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const bottleTmp = {
      ...newBottle,
      scene: {
        ...newBottle.scene,
        scaleBottleX: parseFloat(event.target.value)
      }
    };
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const setNewBottleScaleY = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const bottleTmp = {
      ...newBottle,
      scene: {
        ...newBottle.scene,
        scaleBottleY: parseFloat(event.target.value)
      }
    };
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const setNewBottleScaleZ = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const bottleTmp = {
      ...newBottle,
      scene: {
        ...newBottle.scene,
        scaleBottleZ: parseFloat(event.target.value)
      }
    };
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const setSceneParam = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const bottleTmp = {
      ...newBottle,
      scene: {
        ...newBottle.scene,
        [event.target.id]: parseInt(event.target.value)
      }
    };
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };
  const setSceneParamInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const bottleTmp = {
      ...newBottle,
      scene: {
        ...newBottle.scene,
        [event.target.id]: parseFloat(event.target.value)
      }
    };
    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const setNewBottleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const bottleTmp = { ...newBottle, [event.target.id]: event.target.value };

    if (!event.target.validity.patternMismatch && !event.target.validity.valueMissing) {
      //pour ne par reload le 3d à chaque char
      setnewBottle(bottleTmp);
    } else {
      TalkToUser("Pas de champs vide l'ami !");
    }
  };

  const getUserList = async () => {
    setUserList(
      await getAllUsers(
        localStorage.getItem("aimUsername") || currentUser.username,
        localStorage.getItem("aimUserid") || currentUser.id
      )
    );
  };

  useEffect(() => {}, [newBottle]);

  useEffect(() => {
    newBottle.userTargetId = "PublicBottle";
    newBottle.userTargetName = "PublicBottle";
    //insert public as default target
    // userTargetRef.current!.value = "public";
    getUserList();
  }, []);

  const resetThenSetDropList = (id: string, username: string): void => {
    setUserListHead(`${username}`);
    newBottle.userTargetId = id;
    newBottle.userTargetName = username;
  };

  const sendSub = async () => {
    if (checkAllForm()) {
      try {
        newBottle.userId = currentUser.id;
        newBottle.userName = currentUser.username;
        newBottle.createdAt = Date.now();
        newBottle.unlockTime = +Date.now() + +(newBottle.lockTime * 60 * 60 * 1000); //heure en ms
        if (await isLogged()) {
          throwNewBottle(newBottle, currentUser);
        } else {
          hardLogout();
          window.open("/", "_self");
        }
      } catch (error) {
        TalkToUser(("[SECURITY VIOLATION]" + " : " + error + "Reload in 3 seconds") as string);
        hardLogout();
        setTimeout(() => {
          window.open("/", "_self");
        }, 5000);
      }
    } else {
      TalkToUser("Svp aucun champs vide...aucun aucun !");
    }
  };

  return (
    <div className="flex flex-col rounded-2xl border-4">
      <div className="z-0 opacity-5 absolute object-contain ">
        <img src={aimMainBg} alt="Head AimBottled" />
      </div>
      <div className=" z-30">
        <div className="flex justify-center text-white text-4xl">
          Vous êtes prêt à lancer une bouteille dans le metavers ?
        </div>
        <div className="flex justify-center text-robins-egg-blue-500 text-2xl">
          Entrer un Id unique !
        </div>
        <div className="flex justify-center text-robins-egg-blue-400 text-2xl">
          Un titre Incroooyable...
        </div>
        <div className="flex justify-center text-robins-egg-blue-300 text-2xl">
          Et votre message innoubliable !
        </div>
        <div className="flex flex-col justify-center p-10 m-10 rounded-lg hover:bg-electric-violet-100/10 border-2 text-electric-violet-50">
          <div>
            <form className="space-x-4">
              <div className="flex justify-center flex-row border-2 rounded-3xl  hover:text-white">
                <div className="flex m-4">
                  <div className="flex justify-center p-1 m-2">
                    <label className="m-2">Id:</label>
                  </div>
                  <div>
                    <input
                      className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                      id={"_id"}
                      onBlur={setNewBottle}
                      type="text"
                      name="id"
                      placeholder={"ID"}
                      defaultValue={newBottle._id}
                      required
                    />
                  </div>
                </div>
                <div className="flex m-4 ">
                  <div className="flex justify-center p-1 m-2">
                    <label className="m-2">Title:</label>
                  </div>
                  <div>
                    <input
                      className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                      id={"title"}
                      onBlur={setNewBottle}
                      type="text"
                      name="title"
                      placeholder={"Title"}
                      defaultValue={newBottle.title}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-6 justify-center flex-row  hover:text-white">
                <div className="flex flex-col m-4 justify-center">
                  <label>Message:</label>
                </div>
                <div className="flex flex-col m-4 justify-center">
                  <textarea
                    className="border-electric-violet-100 text-xl border-2 p-1 outline-none bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0 hover:border-white   bg-white  m-2 rounded-xl"
                    id={"message"}
                    onBlur={setNewBottleTextArea}
                    name="message"
                    placeholder={"Message"}
                    defaultValue={newBottle.message}
                    rows={7}
                    cols={50}
                    required
                  />
                </div>
              </div>
              <div className="flex justify-center flex-row border-2 rounded-3xl  hover:text-white">
                <div className="flex m-4">
                  <div className="flex justify-center p-1 m-2">
                    <label className="m-2">lockTime(hours):</label>
                  </div>
                  <div>
                    <input
                      className="border-electric-violet-100h-10 text-xl border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                      id={"lockTime"}
                      ref={refInputLock}
                      onChange={setNewBottle}
                      type="number"
                      step="0.01"
                      name="lockTime"
                      placeholder={"lockTime"}
                      defaultValue={newBottle.lockTime}
                      required
                    />
                  </div>
                </div>
                <div className="flex m-4">
                  <div className="flex justify-center p-1 m-2">
                    <label className="m-2">User Targeted:</label>
                  </div>
                  <div className="w-56 z-10">
                    <DropList
                      title={userListHead}
                      list={userList}
                      resetThenSetDropList={resetThenSetDropList}></DropList>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-center m-4 justify-center border-2 rounded-3xl  hover:text-white">
                <div>Configuration</div>
                <div className="flex justify-center">
                  <div className="flex m-4 justify-center">
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Modèle :</label>
                    </div>
                    <div>
                      <select
                        className="border-electric-violet-100h-10 w-44 text-xl hover:text-black border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-electric-violet-500 m-2 rounded-xl"
                        id={"bottleModel"}
                        onChange={setSceneParam}
                        name="bottleModel"
                        placeholder={"bottleModel"}
                        defaultValue={newBottle.scene.bottleModel}
                        required>
                        <option value="0">Contemporaine</option>
                        <option value="1">futuristique</option>
                        <option value="2">Royale</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="flex m-4 justify-center">
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Scale-X:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"scaleBottleX"}
                        onChange={setNewBottleScaleX}
                        type="number"
                        step="0.1"
                        name="scaleBottleX"
                        placeholder={"scaleBottleX"}
                        defaultValue={newBottle.scene.scaleBottleX}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex m-4 justify-center">
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Scale-Y:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"scaleBottleY"}
                        onChange={setNewBottleScaleY}
                        type="number"
                        step="0.1"
                        name="scaleBottleY"
                        placeholder={"scaleBottleY"}
                        defaultValue={newBottle.scene.scaleBottleY}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex m-4 justify-center">
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Scale-Z:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"scaleBottleZ"}
                        onChange={setNewBottleScaleZ}
                        type="number"
                        step="0.1"
                        name="scaleBottleZ"
                        placeholder={"scaleBottleZ"}
                        defaultValue={newBottle.scene.scaleBottleZ}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="flex m-4 justify-center">
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Sky:</label>
                    </div>
                    <div>
                      <select
                        className="border-electric-violet-100h-10 text-xl hover:text-black w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-electric-violet-500 m-2 rounded-xl"
                        id={"skybox"}
                        onChange={setSceneParam}
                        name="skybox"
                        placeholder={"skybox"}
                        defaultValue={newBottle.scene.skybox}
                        required>
                        <option value="0">Desert</option>
                        <option value="1">Pylonne</option>
                        <option value="2">La rue</option>
                        <option value="3">Italia</option>
                        <option value="4">Lune</option>
                        <option value="5">foret</option>
                        <option value="6">Volcano</option>
                        <option value="7">Space</option>
                      </select>
                    </div>
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Couleur:</label>
                    </div>
                    <div>
                      <select
                        className="border-electric-violet-100h-10 text-xl hover:text-black w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-electric-violet-500 m-2 rounded-xl"
                        id={"texture"}
                        onChange={setSceneParam}
                        name="texture"
                        placeholder={"texture"}
                        defaultValue={newBottle.scene.texture}
                        required>
                        <option value="0">Bleue</option>
                        <option value="1">Verte</option>
                        <option value="2">Rouge</option>
                        <option value="3">Jaune</option>
                        <option value="4">Rose</option>
                      </select>
                    </div>
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Opacité:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"opacity"}
                        onChange={setSceneParamInput}
                        type="number"
                        step="0.1"
                        name="opacity"
                        placeholder={"opacity"}
                        defaultValue={newBottle.scene.opacity}
                        required
                      />
                    </div>
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Metalness:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"metalness"}
                        onChange={setSceneParamInput}
                        type="number"
                        step="0.1"
                        name="metalness"
                        placeholder={"metalness"}
                        defaultValue={newBottle.scene.metalness}
                        required
                      />
                    </div>
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Roughness:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"roughness"}
                        onChange={setSceneParamInput}
                        type="number"
                        step="0.1"
                        name="roughness"
                        placeholder={"roughness"}
                        defaultValue={newBottle.scene.roughness}
                        required
                      />
                    </div>
                    <div className="flex justify-center p-1 m-2">
                      <label className="m-2">Sky Opacity:</label>
                    </div>
                    <div>
                      <input
                        className="border-electric-violet-100h-10 text-xl w-20 border-2 p-1 outline-none  bg-electric-violet-900/20 valid:bg-electric-violet-200/10 valid:border-0   bg-white  m-2 rounded-xl"
                        id={"skyOpacity"}
                        onChange={setSceneParamInput}
                        type="number"
                        step="0.1"
                        name="skyOpacity"
                        placeholder={"skyOpacity"}
                        defaultValue={newBottle.scene.skyOpacity}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-center m-4 justify-center border-2 rounded-3xl  hover:text-white">
                <div>Prévisuel</div>
                <div>
                  <Bottle key={uuidv4()} bottle={newBottle} loaders={loaders} />
                </div>
              </div>
            </form>
          </div>
          <div
            onClick={sendSub}
            className="flex justify-center border-2 p-2 mt-8 hover:bg-electric-violet-200/60 rounded-xl  hover:text-white">
            <button>submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottleThrow;
