import React, { useContext } from "react";
import { logout } from "../services/authService";
import { AuthContext } from "../contexts/AuthProvider";
import { UserContextType } from "../types/userType";

const Logout: React.FC = () => {
  const { currentUser } = useContext(AuthContext) as UserContextType;

  const logUserOut = (): void => {
    logout(currentUser);
  };

  return (
    <div
      onClick={logUserOut}
      className="flex h-10 w-20 justify-center border-2 p-2 m-1 hover:bg-electric-violet-200/60 rounded-xl  hover:text-white">
      <button>Logout</button>
    </div>
  );
};

export default Logout;
