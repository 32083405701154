/* eslint-disable no-unused-vars */
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export function TalkToUser(message: string) {
  const divMenu = document.getElementById("toast") as HTMLDivElement;
  const divMessage = document.getElementById("message") as HTMLDivElement;

  divMenu.className =
    "fixed visible items-center z-50 top-2/4 left-3/4 text-white p-2 border-2 hover:bg-electric-violet-900/30 bg-electric-violet-900/50 rounded-xl  hover:text-white";
  divMessage.innerText = message;
  setTimeout(() => {
    divMenu.className =
      "fixed visible items-center z-50 top-2/4 left-3/4 text-white p-2 border-2 hover:bg-electric-violet-900/30 bg-electric-violet-900/50 rounded-xl  hover:text-white transition-opacity ease-out duration-1000  opacity-0";
  }, 150000);

  document.addEventListener("click", (evt: MouseEvent) => {
    if (divMenu.contains(evt.target as Node)) {
      divMenu.className =
        "fixed visible items-center z-50 top-2/4 left-3/4 text-white p-2 border-2 hover:bg-electric-violet-900/30 bg-electric-violet-900/50 rounded-xl  hover:text-white transition-opacity ease-out duration-1000  opacity-0";
    }
  });
}

export function getCookie(cookieName: string) {
  let cookie: any = {};
  document.cookie.split(";").forEach(function (el) {
    let [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });
  return cookie[cookieName];
}

export const parseMsToDateString = (dateMs: number): string => {
  const date = new Date(dateMs);

  let day: string = "";
  let month: string = "";

  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  } else {
    day = date.getDate().toString();
  }

  if (date.getMonth() < 10) {
    month = "0" + (date.getMonth() + 1);
  } else {
    month = date.getMonth().toString();
  }

  // return ` ${day}/${month}/${date.getFullYear()}`;
  return date.toDateString() + " at " + date.getHours() + "h" + date.getMinutes();
};

export const random_hex_color_code = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
};

export const setupGlftModelSingleChild = (glftData: GLTF) => {
  const model = glftData.scene.children[0];
  return model as any;
};
