/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { bottle } from "../types/bottleType";
import { SideBottleScene } from "./class/SideBottleScene";
import { UniqueLoaders } from "./class/UniqueLoaders";

type IProps = {
  bottle: bottle;
  loaders: UniqueLoaders;
};

const SideBottle: React.FC<IProps> = ({ bottle, loaders }) => {
  const [sideBottleScene, setSideBottleScene] = useState<SideBottleScene | null>();
  const [bottleState] = useState<bottle>(bottle);

  const refCanvasThree = useRef<HTMLCanvasElement>(null);
  const refDivCanvasThree = useRef<HTMLDivElement>(null);
  const refWideSizeBottle = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sideBottleScene = new SideBottleScene(
      refCanvasThree.current as HTMLCanvasElement,
      refDivCanvasThree.current as HTMLDivElement,
      refWideSizeBottle.current as HTMLDivElement,
      bottleState,
      loaders
    );

    setSideBottleScene(sideBottleScene);

    function onKeyDown(e: any) {
      if (e.key === "Enter") {
        console.log(e);
      }
    }
    window.addEventListener("keydown", onKeyDown);
    // window.addEventListener("keydown", (keydown) => {
    //   // this.destroy();
    //   console.log(keydown);
    // });

    return () => {
      sideBottleScene.destroy();
      // document.removeEventListener("keydown", onKeyDown);

      // setSideBottleScene(null);
    };
  }, []);

  useEffect(() => {
    sideBottleScene?.tick();
  }, [sideBottleScene]);

  const stringXpand = `<--Xpand-->`;

  return (
    <div ref={refDivCanvasThree} className="flex flex-col w-full h-full justify-top items-end">
      <div
        className="z-10 m-4 p-[3px] text-[9px] border-[0.5px] rounded-lg hover:bg-electric-violet-200/80 hover:cursor-pointer"
        ref={refWideSizeBottle}>
        {stringXpand}
      </div>
      <div className="absolute">
        <canvas ref={refCanvasThree}></canvas>
      </div>
    </div>
  );
};

export default SideBottle;
