import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type IProps = {
  name: string;
  icon: FontAwesomeIconProps["icon"];
};

const TopMenuItem: React.FC<IProps> = (props) => {
  return (
    <div className="hover:bg-white/30 mt-1 rounded-2xl flex items-center cursor-pointer">
      <div className="p-1 m-3">
        <FontAwesomeIcon icon={props.icon} size="lg" />
      </div>
      <div className="font-josefin m-2 text-sm pt-2">{props.name}</div>
    </div>
  );
};

export default TopMenuItem;
